import { action, computed, observable, reaction } from 'mobx';
import { DateTime } from 'luxon';

import httpFacade from 'http/httpFacade';
import { IntervalFetch } from 'http/intervalFetch';
import Log from 'helpers/log';
import { getDate, getLocalDateTime } from 'helpers/datetime';
import { OrderModel, SwipeDirection } from '../UserProfile/types';
import { OrderLocation } from './type';
import RootStore from '../RootStore';
import { MOBILE_DEVICES } from '../constants';
import { NewOnlineOrdersData, SocketDataType } from 'stores/Socket/types';
import { sortByAccessor } from 'helpers/accessors';

class OnlineOrdersStore {
  @observable id: string;
  @observable searchValue: string = '';
  @observable ordersData: OrderModel[] = [];
  @observable currentDate: DateTime = getLocalDateTime();
  @observable activeDate: DateTime = getLocalDateTime();
  @observable intervalFetch: IntervalFetch = new IntervalFetch();
  @observable selectedLocation: string = ' ';
  @observable locations: OrderLocation[] = [];
  @observable orders: OrderModel[] = [];

  @observable filters: { place: string; status: string } = {
    place: ' ',
    status: 'pending',
  };
  @observable loading: boolean = false;
  private markRowsTimeout;

  private vorwerkOnlineOrdersSort = sortByAccessor({
    accessor: 'queueNumber',
    desc: false,
  });

  constructor() {
    this.intervalFetch.setRequest(() => this.fetchOrders(false));
    reaction(
      () => this.activeDate,
      async () => {
        await this.fetchOrders();
      },
    );
  }

  @action.bound
  async init() {
    try {
      [{ data: this.locations }] = await Promise.all([
        await httpFacade.onlineOrders.fetchOrderLocations(),
        await this.intervalFetch.start(),
      ]);
    } catch (error) {
      Log.warn(error);
    }
    this.markTableRows();
  }

  @action.bound
  applyFilter(filters: { place: string; status: string }) {
    this.filters = filters;

    this.orders = this.ordersData.filter(order => {
      const matchesPlace =
        filters.place === ' ' || order.consumptionCondition === filters.place;

      let matchesStatus = true;
      if (filters.status === 'delivered') {
        matchesStatus = order.orderProcessingFinished;
      } else if (filters.status === 'pending') {
        matchesStatus = !order.orderProcessingFinished;
      } else if (filters.status === ' ') {
        matchesStatus = true;
      }

      const matchesSearchValue = this.searchValue
        ? this.regExpQueNumber.test(String(order.queueNumber))
        : true;

      return matchesPlace && matchesStatus && matchesSearchValue;
    });
  }

  @computed
  get orderLocations() {
    const formattedlocations = this.locations.map(location => {
      return { value: location.id, label: location.title };
    });
    return [
      {
        value: '',
        label: RootStore.localization.formatMessage('title.order.places.all'),
      },
      ...formattedlocations,
    ];
  }

  @computed
  get regExpQueNumber() {
    return new RegExp(this.searchValue, 'i');
  }

  @computed
  get regExpLocation() {
    return new RegExp(this.selectedLocation, 'i');
  }

  @computed
  get isCurrentDay() {
    return this.activeDate.toISODate() === this.currentDate.toISODate();
  }

  @computed
  get isMobileOrTablet() {
    const devices = new RegExp(MOBILE_DEVICES, 'i');
    const msStream = 'MSStream';
    const isIOS =
      (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      (!window.hasOwnProperty(msStream) || !window[msStream]);

    return devices.test(navigator.userAgent) || isIOS;
  }

  @action.bound
  async fetchOrders(withLoading: boolean = true) {
    try {
      if (withLoading) {
        this.loading = true;
      }
      const requestDate = getDate(this.activeDate.toJSDate());
      const { data } = await httpFacade.onlineOrders.fetchOnlineOrders({
        from: requestDate,
        to: requestDate,
      });
      const changedOrders = data.map(items => {
        items?.orders.map(it => {
          it.deliveryTimeSlot = items.deliveryTimeSlot;
          return it;
        });
        return items;
      });
      const orders = changedOrders
        .map(group => group.orders)
        .flat()
        .slice();
      const latestOrderNumber = orders.reduce((acc, o) => {
        return Math.max(acc, o.queueNumber);
      }, 0);
      const localsLatestOrderNumber = this.ordersData.reduce((acc, o) => {
        return Math.max(acc, o.queueNumber);
      }, 0);
      if (latestOrderNumber !== localsLatestOrderNumber) {
        this.ordersData = orders.sort(
          window.location.hostname.includes('vorwerk')
            ? this.vorwerkOnlineOrdersSort
            : this.defaultOnlineOrdersSort,
        );
        this.applyFilter(this.filters);
        clearTimeout(this.markRowsTimeout);
        this.markTableRows();
      }
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action
  searchOrder(value) {
    this.searchValue = value;
    this.applyFilter(this.filters);
  }

  @action.bound
  changeDate(date: any) {
    this.activeDate = date;
  }

  @action.bound
  selectLocation(point?: string | null) {
    this.selectedLocation = point || '';
    this.filters.place = this.selectedLocation;
    this.applyFilter(this.filters);
  }

  @action.bound
  resetFilter() {
    this.selectedLocation = '';
    this.searchValue = '';
    this.filters = { place: '', status: 'pending' };
    this.applyFilter(this.filters);
  }

  @action.bound
  swipeDay(value: SwipeDirection) {
    this.activeDate =
      value === 'previous'
        ? this.activeDate.minus({ day: 1 })
        : this.activeDate.plus({ day: 1 });
  }

  @action
  clearInterval() {
    this.intervalFetch.stop();
  }

  @action.bound
  async orderCreated(event) {
    const eventData: NewOnlineOrdersData = JSON.parse(event.data);

    if (eventData?.type === SocketDataType.NEW_ONLINE_ORDERS) {
      const lastCreatedDate = DateTime.fromISO(
        eventData.payload.lastCreatedDate,
        { zone: 'utc' },
      );
      const newOrdersAvailable: boolean =
        typeof this.ordersData.find(
          o =>
            DateTime.fromISO(o.createdDate, { zone: 'utc' }) >= lastCreatedDate,
        ) === 'undefined';
      if (newOrdersAvailable) {
        const requestDate = getDate(this.activeDate.toJSDate());
        const { data } = await httpFacade.onlineOrders.fetchOnlineOrders({
          from: requestDate,
          to: requestDate,
          after: eventData.payload.lastCreatedDate,
        });
        const newOrders = data
          .map(items => {
            items?.orders.map(it => {
              it.deliveryTimeSlot = items.deliveryTimeSlot;
              return it;
            });
            return items;
          })
          .map(group => group.orders)
          .flat()
          .slice();
        this.ordersData = [...this.ordersData, ...newOrders];
        this.ordersData = this.ordersData
          .slice()
          .sort(
            window.location.hostname.includes('vorwerk')
              ? this.vorwerkOnlineOrdersSort
              : this.defaultOnlineOrdersSort,
          );
        this.applyFilter(this.filters);
      }
      clearTimeout(this.markRowsTimeout);
      this.markTableRows();
    }
  }

  @action.bound
  markTableRows() {
    this.markRowsTimeout = setTimeout(() => this.markTableRows(), 60000);
    this.orders = this.orders.map(o => {
      o.recentOrder =
        DateTime.fromISO(o.createdDate, { zone: 'utc' }).diffNow('seconds')
          .seconds *
          -1 <
          60 && !o.orderProcessingFinished;
      return o;
    });
  }

  @action.bound
  orderStateUpdated(id: string) {
    this.ordersData = this.ordersData
      .map(o => {
        if (o.id === id) {
          o.orderProcessingFinished = true;
        }
        return o;
      })
      .slice()
      .sort(
        window.location.hostname.includes('vorwerk')
          ? this.vorwerkOnlineOrdersSort
          : this.defaultOnlineOrdersSort,
      );
    this.applyFilter(this.filters);
    clearTimeout(this.markRowsTimeout);
    this.markTableRows();
  }

  private defaultOnlineOrdersSort = (a: OrderModel, b: OrderModel): number => {
    if (a.orderProcessingFinished === b.orderProcessingFinished) {
      return a.queueNumber - b.queueNumber;
    } else {
      return a.orderProcessingFinished ? 1 : -1;
    }
  };
}

export default OnlineOrdersStore;
