import React from 'react';
import { Column } from 'react-table';

import './style.scss';

import { withArrow } from 'components/Table/helpers';
import Icon from 'components/Icon/Icon';
import OrderItemsList from 'components/OrderItemsList/OrderItemsList';

import { getTimeSlotPeriod } from 'stores/UserProfile/helpers';
import { ConsumptionCondition } from 'stores/UserProfile/types';

const config = ({ onOpenOrder, onPrintSticker }): Column[] => [
  {
    Header: withArrow('title.order.number'),
    accessor: 'queueNumber',
    resizable: true,
    minWidth: 100,
    Cell: row => (
      <div
        onClick={() => onOpenOrder(row.value)}
        className={
          'onlineOrders__cell__queueNumber' +
          (row.original.recentOrder
            ? ' onlineOrders__cell__recentOrder onlineOrders__cell__border'
            : '')
        }
      >
        {row.value}
      </div>
    ),
  },
  {
    Header: withArrow('title.order.article'),
    accessor: 'items',
    resizable: true,
    sortable: false,
    minWidth: 200,
    Cell: row => (
      <OrderItemsList items={row.value} recent={row.original.recentOrder} />
    ),
  },
  {
    Header: withArrow('title.order.timeSlot'),
    headerClassName: 'onlineOrders__column__header__deliveryTimeSlot',
    className: 'onlineOrders__column__deliveryTimeSlot',
    accessor: 'deliveryTimeSlot',
    resizable: true,
    sortMethod: (a, b, desc) => {
      if (a === null && b === null) {
        return 0;
      } else if (a === null && b !== null) {
        return !!desc ? 1 : -1;
      } else if (a !== null && b === null) {
        return !!desc ? -1 : 1;
      } else {
        return a.start.localeCompare(b.start);
      }
    },
    minWidth: 80,
    Cell: row =>
      row.value ? (
        <span
          className={
            row.original.recentOrder ? ' onlineOrders__cell__recentOrder' : ''
          }
        >
          {getTimeSlotPeriod(row.value)}
        </span>
      ) : row.original.recentOrder ? (
        <span
          className={
            row.original.recentOrder ? ' onlineOrders__cell__recentOrder' : ''
          }
        />
      ) : (
        ''
      ),
  },
  {
    Header: withArrow('title.order.place'),
    accessor: 'deliveryLocation',
    resizable: true,
    minWidth: 110,
    Cell: row => (
      <span
        className={
          row.original.recentOrder ? ' onlineOrders__cell__recentOrder' : ''
        }
      >
        {row.value?.title ||
          row.original?.deliveryInfo?.pointOfDelivery ||
          (typeof row.original?.deliveryInfo?.tableName !== 'undefined'
            ? row.original?.deliveryInfo?.tableName !== null &&
              row.original?.deliveryInfo?.tableName !== ''
              ? 'Tischnummer: ' + row.original?.deliveryInfo?.tableName
              : row.original?.deliveryInfo?.tableName === ''
              ? 'Im Restaurant'
              : 'Abholung'
            : typeof row.original.consumptionCondition !== 'undefined' &&
              row.original.consumptionCondition !== null
            ? row.original.consumptionCondition ===
              ConsumptionCondition.IN_PLACE
              ? 'Im Restaurant'
              : row.original.consumptionCondition ===
                ConsumptionCondition.TAKE_AWAY
              ? 'Abholung'
              : ''
            : '')}
      </span>
    ),
  },
  {
    Header: withArrow('title.order.control.status'),
    accessor: 'orderProcessingFinished',
    headerClassName: 'rt-tr-align-center',
    resizable: false,
    minWidth: 100,
    Cell: row => {
      return (
        <div
          className={
            row.original.recentOrder ? ' onlineOrders__cell__recentOrder' : ''
          }
          style={{ margin: '0 auto' }}
        >
          {row.value ? (
            <Icon type="success" className={'onlineOrders__icon__delivered'} />
          ) : (
            <Icon type="pending" className={'onlineOrders__icon__pending'} />
          )}
        </div>
      );
    },
  },
  {
    headerClassName: 'rt-tr-align-center onlineOrders__column__header__print',
    className: 'onlineOrders__column__print rt-tr-align-center',
    resizable: false,
    sortable: false,
    width: 60,
    Cell: row => {
      return (
        <div
          className={
            'onlineOrders__btn_print' +
            (row.original.recentOrder ? ' onlineOrders__cell__recentOrder' : '')
          }
          onClick={e => {
            e.stopPropagation();
            onPrintSticker(row.original);
          }}
        >
          <Icon type="print" />
        </div>
      );
    },
  },
];

export default config;
