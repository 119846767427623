import React from 'react';
import { observer } from 'mobx-react';
import { injectIntl } from 'react-intl';

import './style.scss';

import config from './columnsConfig';
import { createStickerContent } from './helpers';
import Table from 'components/Table/Table';

import Orders from './Orders/Orders';
import OnlineOrdersHeader from './OnlineOrdersHeader/OnlineOrdersHeader';
import OnlineOrderModal from 'components/Modals/OnlineOrderModal/OnlineOrderModal';
import FilterModal from 'components/Modals/OnlineOrderFilterModal/OnlineOrderFilterModal';

import OnlineOrdersStore from 'stores/OnlineOrders/OnlineOrdersStore';
import ModalStore from 'stores/ModalStore';
import { OrderModel } from 'stores/UserProfile/types';
import RootStore from 'stores/RootStore';

@observer
class OnlineOrdersPage extends React.Component<{ intl }> {
  store = new OnlineOrdersStore();

  async componentDidMount() {
    await this.store.init();
    RootStore.socket.subscribe(this.store.orderCreated);
  }

  componentWillUnmount() {
    this.store.clearInterval();
    RootStore.socket.unsubscribe(this.store.orderCreated);
  }

  handleOpenOrder = async orderNumber => {
    const currentOrder = this.store?.orders?.find?.(
      order => order.queueNumber === orderNumber,
    );
    await ModalStore.showModal(OnlineOrderModal, {
      order: currentOrder,
      onUpdateOrderStatus: this.onUpdateOrderStatus,
      activeDate: this.store.activeDate,
      onPrintSticker: this.handlePrintSticker,
    });
  };

  handlePrintSticker = (orderItem: OrderModel) => {
    createStickerContent(
      orderItem,

      {
        queueNumberTitle: this.props.intl.formatMessage(
          { id: 'user.order.number.full' },
          { number: orderItem.queueNumber },
        ),
        deliveryLocationTitle: this.props.intl.formatMessage(
          { id: 'user.order.delivery.location' },
          { location: orderItem?.deliveryLocation?.title ?? '' },
        ),
      },
    );
    window.print();
  };

  onUpdateOrderStatus = (id: string) => {
    this.store.orderStateUpdated(id);
  };

  handleFilterClick = async () => {
    const { intl } = this.props;
    await ModalStore.showModal(FilterModal, {
      onApplyFilter: this.store.applyFilter,
      filters: this.store.filters,
      intl,
    });
  };

  render() {
    const { isMobileOrTablet, orders, loading } = this.store;

    return (
      <>
        <div className={'onlineOrders__main'}>
          <OnlineOrdersHeader
            store={this.store}
            onUpdateOrderStatus={this.onUpdateOrderStatus}
            onFilterClick={this.handleFilterClick}
          />

          {isMobileOrTablet ? (
            <Orders
              orders={orders}
              onOpenOrder={this.handleOpenOrder}
              onPrintSticker={this.handlePrintSticker}
            />
          ) : (
            <Table
              data={orders}
              columns={config({
                onOpenOrder: this.handleOpenOrder,
                onPrintSticker: this.handlePrintSticker,
              })}
              loading={loading}
            />
          )}
        </div>
        {/* Print sticker*/}
        <div id={'print'} />
      </>
    );
  }
}
export default injectIntl(OnlineOrdersPage);
